var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('calendar-filters',{attrs:{"options":{
      classes: _vm.classes,
      adminUsers: _vm.adminUsers,
      providerUsers: _vm.providerUsers,
      patientUsers: _vm.patientUsers,
    },"users":_vm.users,"profileAdminUserId":_vm.profileAdminUserId,"profileProviderUserId":_vm.profileProviderUserId,"profilePatientUserId":_vm.profilePatientUserId},on:{"filterChanged":_vm.filtersChanged,"openUserAvailability":_vm.openUserAvailability,"openBulkSessionsModal":_vm.openBulkSessionsModal}}),_c('p',{staticClass:"mobile-tablet-only text-danger noPrint"},[_vm._v(" Hint: Long press to create a session ")]),_c('calendar-print',{attrs:{"profileAdminUserId":_vm.profileAdminUserId,"eventsToPrint":_vm.eventsToPrint,"selectedProvider":_vm.selectedProvider,"selectedPatient":_vm.selectedPatient,"patientData":_vm.patientData,"companyProfile":_vm.companyProfile,"calendarTitle":_vm.calendarTitle}}),_c('div',{staticClass:"calendar-wrapper noPrint"},[_c('button',{staticClass:"btn btn-black noPrint print-btn",attrs:{"type":"button"},on:{"click":_vm.print}},[_c('i',{staticClass:"fas fa-print mr-2"}),_vm._v(" Print ")]),(_vm.user && !_vm.user.isPatient)?_c('div',{staticClass:"total-hours-info"},[_c('table',[_c('tr',[_c('td',{staticClass:"text-right pr-2"},[_vm._v("Total Billable:")]),_c('td',{staticClass:"text-right font-weight-bold"},[(_vm.loading)?_c('i',{staticClass:"fas fa-spin fa-circle-notch"}):_vm._e(),_vm._v(_vm._s(_vm.loading ? "" : _vm.totalBillable)+" ")])]),_c('tr',[_c('td',{staticClass:"text-right pr-2"},[_vm._v("Total Non Billable:")]),_c('td',{staticClass:"text-right font-weight-bold"},[(_vm.loading)?_c('i',{staticClass:"fas fa-spin fa-circle-notch"}):_vm._e(),_vm._v(_vm._s(_vm.loading ? "" : _vm.totalNonBillable)+" ")])])]),_c('i',{staticClass:"ml-4 fas fa-lg pointer-cursor noPrint",class:`${_vm.hideDeleted ? 'fa-eye-slash' : 'fa-eye'}`,staticStyle:{"width":"30px"},attrs:{"title":_vm.hideDeleted
            ? 'Removed sessions are hidden'
            : 'Removed sessions are visible'},on:{"click":function($event){return _vm.toggleDeletedStatus()}}})]):_vm._e(),_c('full-calendar',{ref:"fullCalendar",attrs:{"options":_vm.calendar}}),(_vm.loading || _vm.gettingUsers)?_c('loader'):_vm._e()],1),_c('edit-calendar-session',{ref:"editSessionModal",attrs:{"currentProvider":_vm.currentProvider,"profilePatientUserId":_vm.profilePatientUserId,"nonBillableServices":_vm.nonBillableServices,"parentServicesTemp":_vm.parentServicesTemp,"services":_vm.services,"providers":_vm.providers,"patients":_vm.patients,"users":_vm.users,"options":{
      adminUsers: _vm.adminUsers,
      providerUsers: _vm.providerUsers,
      patientUsers: _vm.patientUsers,
    }},on:{"refetchCalendar":_vm.refetchCalendar,"openUserAvailability":_vm.openUserAvailability}}),_c('create-calendar-session',{ref:"createSessionModal",attrs:{"currentProvider":_vm.currentProvider,"nonBillableServices":_vm.nonBillableServices,"parentServicesTemp":_vm.parentServicesTemp,"services":_vm.services,"providers":_vm.providers,"patients":_vm.patients,"users":_vm.users,"options":{
      adminUsers: _vm.adminUsers,
      providerUsers: _vm.providerUsers,
      patientUsers: _vm.patientUsers,
    }},on:{"refetchCalendar":_vm.refetchCalendar,"closeModal":_vm.closeModal,"openUserAvailability":_vm.openUserAvailability}}),_c('calendar-clone-modal',{on:{"refetchCalendar":_vm.refetchCalendar}}),_c('calendar-bulk-modal',{ref:"bulkModal",attrs:{"options":{
      classes: _vm.classes,
      services: _vm.parentServicesTemp,
      adminUsers: _vm.adminUsers,
      providerUsers: _vm.providerUsers,
      patientUsers: _vm.patientUsers,
    },"profileAdminUserId":_vm.profileAdminUserId,"profileProviderUserId":_vm.profileProviderUserId,"profilePatientUserId":_vm.profilePatientUserId,"initialFilter":_vm.filter},on:{"refetchCalendar":_vm.refetchCalendar}}),_c('calendar-availability-modal',{ref:"availabilityModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }